import { axiosWrapper } from '../services/axios.wrapper'
import * as utility from "../utilities/utils";
import {
    getCardConfirmationRequest, getCardConfirmationHeader, getTrackingRequestPayload, getTrackingRequestHeaders, getSwitchHeader,
    getNuDataLogsHeader, getNudataLogsRequestPayload
} from '../utilities/utils';
import {Buffer} from 'buffer';

export const postCardConfirmationInfo = (formData, nuData, sessionID, productType) => {

    let confirmUrl = ''
    confirmUrl = '/eca/api/v1/'
    let endPoint = ''
    endPoint = 'confirm'
    var headers = getCardConfirmationHeader(formData.eventID, sessionID);
    let buff = new Buffer.from(nuData);
    let nuDataBase64 = buff.toString('base64');
    var cardConfirmationRequest = getCardConfirmationRequest(formData, nuDataBase64, sessionID, formData.eventID, productType)
    return axiosWrapper.post(
        confirmUrl,
        endPoint,
        headers,
        JSON.stringify(cardConfirmationRequest))
}

export const switchInfo = (eventID, partner, sessionID) => {
   let switchUrl = ''
    switchUrl = '/eca/api/v1/switch'

    var headers = getSwitchHeader(eventID, partner, sessionID);
    return axiosWrapper.get(
        switchUrl,
        headers)
}

export const environmentVaribleInfo = (language, flowInd, partner, eventID, useraction, sessionID) => {

    let configUrl = ''
    configUrl ='/eca/api/v1/ecaconfig?language=' + language + '&flowind=' + flowInd + '&partner=' + partner + '&useraction=' + useraction

    var headers = {
        'WF-senderMessageId': eventID,
        "SessionID": sessionID
    };
    return axiosWrapper.get(configUrl, headers)
}

export const trackEvent = (page, event, sessionID) => {

    let eventID = utility.getUniqueID()

    let configUrl = ''
    configUrl = '/eca/api/v1/';
    const endPoint = 'trackingevent';

    var headers = getTrackingRequestHeaders(eventID, sessionID);
    const payload = getTrackingRequestPayload(page, event, eventID, sessionID);


    return axiosWrapper.post(configUrl, endPoint, headers, JSON.stringify(payload));
}

export const getNudataLogs = (eventID, sessionID, error)=>{

    let configUrl = ''
    configUrl =   '/eca/api/v1/';
    const endPoint = 'NuDataLogs';

    var headers = getNuDataLogsHeader(eventID,sessionID);
    const payload = getNudataLogsRequestPayload(eventID, sessionID, error);

    return axiosWrapper.post(configUrl, endPoint, headers, JSON.stringify(payload));
}